import IntervalTree from "@flatten-js/interval-tree";

/* Services */
import { fetchCategories } from "../services/category.service";
import { fetchModules } from "../services/module.service";
import { fetchDiscounts } from "../services/discount.service";

/* Constants */
import { CategoryType } from "../constants/categorytype.constant";

const MAX_QUANTITY = 999999;

const mapCategoriesWithModules = (categories, modules) => {
    const sortedCategories = categories.sort((c1, c2) => c1.sortPos - c2.sortPos);
    const sortedModules = modules.sort((c1, c2) => c1.sortPos - c2.sortPos);
    const categoryMap = sortedCategories.reduce((result, category) => {
        result[category.id] = {
            ...category,
            items: [],
            options: []
        };
        return result;
    }, {});

    sortedModules.forEach(({
        id,
        sortPos,
        groupId,
        name,
        price,
        yearPrice,
        discountId
    }) => {
        if (categoryMap?.[groupId]?.type === CategoryType.REGULAR || categoryMap?.[groupId]?.type === CategoryType.COUNTER) {
            categoryMap?.[groupId]?.items?.push({
                id,
                sortPos,
                name,
                price,
                yearPrice,
                discountId,
                quantity: 0
            });    
        }
        else if (categoryMap?.[groupId]?.type === CategoryType.SELECTABLE) {
            categoryMap?.[groupId]?.options?.push({
                key: `m-${groupId}-${id}`,
                id,
                name,
                quantity: yearPrice // bad mapping on backend/db side
            });
        }
    });

    const newCategories = sortedCategories.map(({ id }) => categoryMap[id]);

    return newCategories;
};

const mapDiscounts = (discounts) => {
    const discountMap = discounts.reduce((result, { id, minQuantity, discount }) => {
        if (!result[id]) {
            result[id] = {
                items: [],
                tree: new IntervalTree(),
            };
        }
        result[id]?.items?.push({ minQuantity, discount });
        result[id]?.tree?.insert([minQuantity, MAX_QUANTITY], discount);
        return result;
    }, {});
    return discountMap;
};

export const fetchAppData = async () => {
    const categories = await fetchCategories();
    const modules = await fetchModules();
    const discounts = await fetchDiscounts();
    const mappedCategories = mapCategoriesWithModules(categories, modules);
    const mappedDiscounts = mapDiscounts(discounts);

    return {
        mappedCategories,
        mappedDiscounts
    }
};