import axios /*, { AxiosRequestConfig } */ from 'axios';
// import AxiosMockAdapter from 'axios-mock-adapter';
// import { BackendAPI } from '../constants/api.constant';
// import { categoryMockData, discountMockData, moduleMockData } from './mock.util';

const api = axios.create();
// const axiosLiveInstance = axios.create();

// export const axiosMockAdapterInstance = new AxiosMockAdapter(api, { delayResponse: 2 });

// axiosMockAdapterInstance.onPost(BackendAPI.getPathForEndpoint(BackendAPI.endpoint.order.submit))
// .reply(200, {
    
// });

// axiosMockAdapterInstance.onGet(BackendAPI.getPathForEndpoint(BackendAPI.endpoint.category.list))
// // .reply(200, [...categoryMockData]);
// .passThrough();

// axiosMockAdapterInstance.onGet(BackendAPI.getPathForEndpoint(BackendAPI.endpoint.module.list))
// // .reply(200, [...moduleMockData]);
// .passThrough();


// axiosMockAdapterInstance.onGet(BackendAPI.getPathForEndpoint(BackendAPI.endpoint.discount.list))
// // .reply(200, [...discountMockData]);
// .passThrough();

export default api;