import { CategoryType } from "../constants/categorytype.constant";

const getLastItemFromArray = (array) => array?.length > 0 ? array?.[array.length - 1] : 0;

const computeStats = (item, discountMap, fixedQuantity = -1) => {
    const { name, quantity: q, price, yearPrice, discountId } = item;
    const quantity = fixedQuantity >= 0 ? fixedQuantity : q;
    const discount = ((discountId ? getLastItemFromArray(discountMap[discountId]?.tree?.search([quantity, quantity])) : 0) || 0);
    const priceQuotaWithDiscount = (1 - (discount / 100));

    return {
        name,
        quantity,
        price: price,
        totalPrice: priceQuotaWithDiscount * price * quantity,
        discount: discount,
        yearPrice: yearPrice,
        totalYearPrice: priceQuotaWithDiscount * yearPrice * quantity,
        yearDiscount: discount,
    }
}

export const computeItemStats = (type, item, discountMap, options = {}) => {
    switch (type) {
        case CategoryType.REGULAR: {
            return computeStats(item, discountMap)
        }
        case CategoryType.SELECTABLE: {
            return item;
        }
        case CategoryType.COUNTER: {
            const { fixedQuantity } = options;
            return computeStats(item, discountMap, fixedQuantity)
        }
        default: return item;
    }
}