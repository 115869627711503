import { useCallback, useState } from "react";

export const useAsyncLoading = (initialState = false) => {
    const [isLoading, setIsLoading] = useState(initialState);

    const asyncLoad = (asyncFn) => {
        setIsLoading(true);
        asyncFn.finally(() => setIsLoading(false));
    };

    return [isLoading, asyncLoad];
};

export const useAsyncLoadingWithToggle = (initialState = false) => {
    const [isLoading, setIsLoading] = useState(initialState);

    const asyncLoad = (asyncFn) => {
        setIsLoading(true);
        asyncFn.finally(() => setIsLoading(false));
    };

    const enableIsLoading = useCallback(() => setIsLoading(true), [setIsLoading]);
    const disableIsLoading = useCallback(() => setIsLoading(false), [setIsLoading]);

    return [isLoading, asyncLoad, enableIsLoading, disableIsLoading];
};