import {
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react';
import React from 'react';
import { CategoryType } from '../constants/categorytype.constant';
import { computeItemStats } from '../utils/calc.util';

const TableCounterCategory = ({ index, name, items, discountMap, quantity }) => {
    return (
        <>
            <Thead>
                <Tr>
                    <Th>{name}</Th>
                    <Th>Anzahl</Th>
                    <Th colSpan={2} textAlign={'right'}>Monatliche Kosten</Th>
                    <Th textAlign={'right'}>Rabatt</Th>
                </Tr>
            </Thead>
            <Tbody>
                {items.map((item, index) => {
                    const {
                        name,
                        // quantity,
                        price,
                        totalPrice,
                        discount
                    } = computeItemStats(CategoryType.COUNTER, item, discountMap, { fixedQuantity: quantity });
                    
                    return (
                        <Tr key={`ri-${index}`} bg={ quantity > 0 ? 'gray.200' : null }>
                            <Td>{name}</Td>
                            <Td>
                                <Text>{quantity}</Text>
                            </Td>
                            <Td textAlign={'right'}>{price?.toFixed(2)}</Td>
                            <Td textAlign={'right'}>{totalPrice?.toFixed(2)}</Td>
                            <Td textAlign={'right'} bg={discount > 0 ? 'gray.300' : null }>{discount}%</Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </>
    );
};

export default TableCounterCategory;