import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  Grid,
  theme,
  Image,
  Center,
  Link,
} from '@chakra-ui/react';
import logo from './resources/images/logo.png';
import background from './resources/images/bg.jpg';
import PriceForm from './components/PriceForm.component';


function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3} style={{backgroundImage: `url(${background})`, backgroundColor: '#111111', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <VStack
            spacing={4}
            align='stretch'
          >
            <Box alignSelf={'center'} style={{padding: '30px'}}>
              <Link href='https://andeer.net'>
                <Image src={logo} alt='Andeer Software' maxWidth={'300px'} />
              </Link>
            </Box>
            {/* <Center> */}
            <Box alignSelf={'center'} style={{
              width: '90%',
              maxWidth: '1100px',
            }}>
              <div style={{
                  backgroundColor: '#fafafa',
                  padding: '10px',
                  borderRadius: '10px',
                  opacity: 0.9,
                  // margin: '0 auto'
                }}>
                <PriceForm />
              </div>
            </Box>
            {/* </Center> */}
          </VStack>
          
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
